<template>
  <div class="bodycont">

    <comHeader />
    <div class="main">
      <div class="news_list">
           <infonavtab   :cindex='cindex' />
      </div>
      <div  class="infocont">
        <h4>{{dataInfo.asTitle}}</h4>
        <div class="infodiv" v-html="dataInfo.asDetail">

        </div>
      </div>
  </div>

  <bNav />

  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
  import navTitle from '@/components/navtitle.vue'
  import infonavtab from './components/navtababout.vue'
  import api from '@/config/api'
  import {
    getRequst,postRequest
  } from '@/api/index'
  export default {
    components: {
      infonavtab,
      comHeader,
      bNav,
      navTitle,
    },
    data() {
      return {
        dataInfo:{},
        cindex:1,
      }

    },
    mounted() {
         this.getInfo()
    },
    methods: {
      //获取列表
      getInfo() {
        let data = {
          asId: 1,
        }
        postRequest(api.aboutUsId, data).then(res => {
           this.dataInfo = res.data
        })
      },

    }
  }
</script>

<style lang="less" scoped>
  .infodiv {
    /deep/ p {
      margin: 10px 0;
    }
  }
  .news_list {
    width: 200px;
    overflow: hidden;
    float: left;
    .top_title {
      width: 100%;
      height: 54px;
      background: url(../../assets/images/title.png) no-repeat;
      text-align: center;
      cursor: pointer;

      h4 {
        color: #fff;
        font-size: 18px;
        line-height: 54px;
      }
    }
    .zczx {
      padding: 10px 0px;
      background: #fff;

      .cur {
        color: #00A0E9;
      }

      li {
        font-size: 16px;
        color: #333;
        background: #eee;
        display: block;
        width: 100%;
        height: 40px;
        line-height: 40px;
        margin-bottom: 2px;
        text-align: center;
        padding-left: 0;
        border: none;
        position: relative;
        cursor: pointer;
      }
    }
  }

  .bodycont {
    margin: 0px;
    background-size: 100% 100%;
    background-attachment: fixed;
  }

  .main {
    width:1190px;
    margin: 20px auto;
    min-height:73vh;
    display: flex;
    justify-content: space-between;
  }


  .infocont {

    width: 80%;
   padding: 20px;
    // box-shadow: 0px 5px 14px 0 #ddd;

    h4 {
      text-align: center;
      font-weight: bold;
      font-size: 30px;
      margin-bottom: 20px;
    }

    .infodiv {
      /deep/ p {
        margin: 10px 0;
      }
    }

    .imgdiv {
      width: 20%;
      height: 200px;
      margin-right: 20px;
      img{
        width: 100%;
        height: 100%;
      }
    }

    .textinfo {
      width: 80%;
      margin: 0 auto;
      font-size: 16px;
      line-height: 42px;
      text-indent: 2em;
      margin-bottom: 15px;
    }
  }

  .infodiv {
    width: 100%;
  }

</style>
